@import '../../index.scss';


.profile-options-box {
    background-color: white;
    position: absolute;
    top: 90%;
    right: 4%;
    padding: 1%;
    border-radius: 0.375rem;
    z-index: 1;
    border: 0.1rem solid #0000002d;
    font-size: 1rem;
}

.profile-options-box-link {
    cursor: pointer;
}