@import '../../index.scss';


.booking-section {
    padding: 3rem 5rem 3rem 5rem;
    background-color: #FBFCFD;
    position: relative;
}

.calendly-container {
    height: 800px;
    z-index: 100 !important;
}

@include media-breakpoint-down(md) {

    .booking-section {
        padding: $default-padding;
    }

    .calendly-container {
        height: 900px;
    }
}