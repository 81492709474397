@import '../../index.scss';


.student-dashboard-container {
    padding: 5%;
}

.student-dashboard-heading {
    font-size: 1.5rem;
    font-weight: 600;
}

.lesson-link {
    color: $button-color-primary;
}

.not-available-text-container {
    margin-top: 8rem;
}