@import '../../index.scss';


.contact-us-section {
    padding: $default-padding*2;
    margin-top: 2rem;
    //background-color: $primary-color;
    //background-image: linear-gradient(white, $primary-color);
}

.contact-form-container {
    margin-top: 4rem;
    padding-left: $default-padding*3;
    padding-right: $default-padding*3;

    label {
        font-weight: 500;
    }

    input, textarea {
        font-size: 1.1rem;
        border: 0.8px solid;
    }

    .validation-error {
        border: 2px solid red !important;
    }

    .confirmation-message {
        background-color: $button-color-tertiary;
        padding: 0.8rem;
        width: fit-content;
        color: $green;
        font-weight: 500;
        font-size: 1.3rem;
        color: white;
        border-radius: $default-border-radius;
    }
}

@include media-breakpoint-down(md) {

    .contact-us-section {
        padding: $default-padding;
        margin-top: 5rem;
    }

    .contact-form-container {
        padding-left: $default-padding;
        padding-right: $default-padding;
    }
}