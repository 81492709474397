@import '../../index.scss';


.intro-section-container {
    padding-top: $default-padding;
    padding-bottom: $default-padding;
    padding-left: $default-padding*3;
    padding-right: $default-padding*3;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.intro-section-title {
    font-size: 6rem;
}

.intro-section-text {
    font-size: 1.4rem;
}

@include media-breakpoint-down(md) {
    .intro-section-container {
        padding: $default-padding;
        margin-top: 2rem;
    }
}