@import './variables.scss';
@import '/node_modules/bootstrap/scss/functions';
@import '/node_modules/bootstrap/scss/variables';
@import '/node_modules/bootstrap/scss/mixins';
@import 'bootstrap-icons/font/bootstrap-icons.css';


html {
    //overflow-x: hidden;
    margin: 0;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.3rem;
    font-weight: 300;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b {
    font-weight: 600;
}

h1 {
    font-size: 2.7rem;
    font-weight: 400;
    line-height: 4rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.4rem;
}

.red {
    color: #AD1519;
    font-weight: 500;
}

.error-message {
    color: red;
    font-weight: 500;
}

.yellow {
    color: #FABD00;
    font-weight: 500;
}

.primary-background {
    background-image: linear-gradient(white, $primary-color);
}

.blur-and-disable {
    filter: blur(3px);
    pointer-events: none;
}

.hero-title {
    font-size: 5rem;
    font-weight: 300;
}

.hero-subtitle {
    font-size: 1.8rem;
    font-weight: 300;
}

.add-icon {
    font-size: 1.7rem;
    color: $button-color-primary;
    width: auto;
    cursor: pointer;
}

.add-icon:hover {
    color: $button-color-primary-hover;
}

.delete-icon {
    font-size: 1.7rem;
    color: $button-color-danger;
    width: auto;
    cursor: pointer;
}

.delete-icon:hover {
    color: $button-color-danger-hover;
}

.confirm-icon {
    font-size: 1.7rem;
    color: $button-color-success;
    width: auto;
    cursor: pointer;
}

.confirm-icon:hover {
    color: $button-color-success-hover;
}

.mobile-only {
    display: none !important;
}

.btn-large {
    font-size: 1.5rem !important;
}

.btn-primary {
    background-color: $button-color-primary;
    border-radius: 0.5rem;
    border: None;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    box-shadow: $default-box-shadow;
}

.btn-primary:hover {
    background-color: $button-color-primary-hover !important;
}

.btn-secondary {
    background-color: $button-color-secondary;
    border-radius: 0.5rem;
    border: None;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    box-shadow: $default-box-shadow;
}

.btn-secondary:hover {
    background-color: $button-color-secondary-hover !important;
}

.disabled {
    color: $button-color-disabled;
    pointer-events: none;
}

.resource-not-available-text {
    color: #c2c2c2;
}

// MOBILE
@include media-breakpoint-down(md) {
    .mobile-only {
        display: unset !important;
    }

    .desktop-only {
        display: none;
    }

    .hero-title {
        font-size: 3rem;
    }

    .hero-subtitle {
        font-size: 1.5rem;
    }
}