@import '../../index.scss';


.hero-section {
    padding: 7% 30% 7% 30%;
    background: url('../../assets/hero_image.png') no-repeat center;     
    background-size: cover;

    .jumbotron {
        padding: 5%;
        background-color: #FEFEFE;
        border-radius: $default-border-radius;
    }
}

@include media-breakpoint-down(md) {

    .hero-section {
        padding: 18%;
        background-size: 900px;
    
        .jumbotron {
            padding: 0;
        }
    }
}