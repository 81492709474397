@import '../../index.scss';


.courses-description-section {
    padding: $default-padding;
    padding-left: 6rem;
    padding-right: 6rem;

    .extra-margin-top {
        margin-top: 5rem;
    }

    img {
        //border-radius: $default-border-radius;
        box-shadow: $default-box-shadow;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

.courses-description-intro {
    margin-bottom: 4rem;
}

.course-description-container {
    background-color: $secondary-color;
    padding: 1rem;
    border-radius: $default-border-radius;    
}

@include media-breakpoint-down(md) {

    .courses-description-section {
        padding-left: $default-padding;
        padding-right: $default-padding;
    }
}