@import '../../index.scss';


.courses-section {
    //background-color: $primary-color;
    //background-image: linear-gradient(white, $primary-color);
    padding-left: $default-padding;
    padding-right: $default-padding;
    padding-top: 5rem;
}

.courses-card-container {
    margin-top: 4rem;
}

.courses-section-subtitle {
    padding-left: 10%;
    padding-right: 10%;
}

@include media-breakpoint-down(md) {
    .courses-section-subtitle {
        padding-left: $default-padding;
        padding-right: $default-padding;
    } 
}