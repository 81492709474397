@import '../../index.scss';


.navbar {
    background-color: $navbar-color;
    padding: 0 3% 0 3%;
    position: sticky;
    top: 0;
    z-index: 100;

    span {
        cursor: pointer;
        font-size: 1.3rem;
        font-weight: 400;
    }

    span:hover {
        color: #646464;
    }

    img {
        cursor: pointer;
    }

    .language-selection-container {
        position: absolute;
        top: -100%;
        right: -12%;
        width: 8rem;
    }
}

.logo-container {
    width: fit-content;
}

.logo {
    width: 6rem;
    cursor: pointer;
}

.navbar-toggle-icon {
    font-size: 2rem;
    float: right;
    color: #fff;
}

.toggle-icon {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px auto;
    transition: transform 0.3s;
}

.menu-active.toggle-icon:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.menu-active.toggle-icon:nth-child(2) {
    opacity: 0;
}

.menu-active.toggle-icon:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-collapse {
    display: none;
    flex-direction: column;
    padding: 3% 6% 3% 6%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $navbar-color-transparent;
}

.navbar-collapse.menu-active {
    display: block;
}

.navbar-nav {
    list-style: none;
    padding: 0;
}

.nav-item {
    margin-bottom: 0.5rem;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    transition: color 0.3s;
}


@include media-breakpoint-down(md) {

    .navbar {
        padding: 0 2% 0 2%;
    }

    .logo {
        width: 4rem;
    }

    .language-selection-icon {
        width: 1.5rem;
    }
}