@import '../../index.scss';


.signup-form-container {
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.6);
    padding: 8%;
    border-radius: 0.5rem;

    a {
        color: $button-color-primary;
        text-decoration: none;
        font-weight: 700;
    }
    
    a:hover {
        text-decoration: underline;
    }

    label {
        font-weight: 700;
    }
}

.sign-up-button {
    background-color: $button-color-primary;
    color: white;
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
}

.sign-up-button:hover {
    background-color: $button-color-primary-hover !important;
    color: white;
}