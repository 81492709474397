@import '../../index.scss';


.small-hero-section {
    padding: 5% 20% 5% 20%;
    background: url('../../assets/hero_image.png') no-repeat center;     
    background-size: cover;

    .small-hero-title {
        font-size: 4rem;
        font-weight: 300;
    }

    .small-hero-subtitle {
        font-size: 1.6rem;
        font-weight: 300;
    }

    .jumbotron {
        padding: 5%;
        background-color: #FEFEFE;
        border-radius: $default-border-radius;
    }
}

@include media-breakpoint-down(md) {

    .small-hero-section {

        background-size: 900px;

        .small-hero-title {
            font-size: 2.3rem;
        }
    
        .small-hero-subtitle {
            font-size: 1.3rem;
        }
    }
}