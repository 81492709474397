@import '../../index.scss';


.price-card-group {
    padding: $default-padding;
    padding-bottom: 8%;
    border: 2px solid $button-color-secondary;
    border-radius: $default-border-radius;
    position: relative;
}

.cta-button {
    position: absolute;
    bottom: -5%;
    left: 35%;
    padding: 1% 6% 1% 6% !important;
    font-size: 1.5rem !important;
    font-weight: 700;
    background-color: $button-color-tertiary;
    border-radius: 0.5rem;
    border: None;
    box-shadow: $default-box-shadow;
    color: white;
}

.cta-button:hover {
    background-color: $button-color-tertiary-hover;
    color: white;
}

@include media-breakpoint-down(md) {

    .cta-button {
        position: absolute;
        bottom: -2%;
        left: 25%;
    }

    .price-card-group {
        margin: 1%;
        padding-bottom: 15%;
    }
}

@include media-breakpoint-down(lg) {

    .cta-button {
        bottom: -2%;
    }
}