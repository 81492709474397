@import '../../index.scss';


.signup-page {
    background-color: $navbar-color;
    min-height: 100vh;
    padding: 8% 25% 8% 25%;
}

@include media-breakpoint-down(md) {
    .signup-page {
        padding: 4%;
    }
}