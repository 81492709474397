@import '../../index.scss';


.prices-for-companies-section {
    padding: 6rem 5rem 6rem 5rem;

    .features-box {
        border-radius: 2rem;
        border: 0.01rem solid $primary;
        padding: 2rem;
    }

    .features-box-with-image {
        border-radius: 0 0 2rem 2rem;
        padding: 2rem;
        border: 0.01rem solid $primary;
    }

    .contact-button {
        width: 100%;
    }

    img {
        border-radius: 2rem 2rem 0 0;
    }

}

@include media-breakpoint-down(md) {

    .prices-for-companies-section {
        padding: 3rem 1rem 3rem 1rem;
    }

}