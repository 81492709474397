@import '../../index.scss';


.why-us-section-inner-container {
    background-color: $secondary-color;
    margin: 5rem;
    padding: $default-padding;

    ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
}

@include media-breakpoint-down(md) {

    .why-us-section-inner-container {
        margin: 2rem;
        padding: $default-padding;
    }
}