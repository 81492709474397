@import '../../index.scss';


.homework-name {
    color: $button-color-primary !important;
    text-decoration: underline;
}

.homework-name:hover {
    color: $button-color-primary-hover !important;
    cursor: pointer;
}

.small-input {
    width: 3rem !important;
}

.medium-input {
    width: 8rem !important;
}

input[type="file"] {
    font-size: 0.8rem;
}

@include media-breakpoint-down(md) {

    table {
        font-size: 0.6rem;
    }
}
