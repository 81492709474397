@import '../../index.scss';


@keyframes slideUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    30% {
        opacity: 0.3;
    }
        
    60% {
        opacity: 0.8;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.cookies-disclaimer {
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    position: fixed;
    bottom: 5%;
    left: 5%;
    z-index: 100;
    border-radius: 0.5rem;
    box-shadow: $default-box-shadow;
    background-color: white;
    font-size: 1rem;
    width: 75%;

    a {
        color: inherit;
    }

    .close-disclaimer-icon {
        position: absolute;
        right: 4%;
        -webkit-text-stroke: 1.5px;
        cursor: pointer;
    }

    .cookies-disclaimer-logo {
        font-weight: 500;
        font-size: 1.2rem;
    }

    .cookies-disclaimer-title {
        font-weight: 700;
    }

    .cookies-disclaimer-header {
        background-color: $secondary-color;
        padding: 1rem 2rem 1rem 2rem;
        border-radius: 0.5rem 0.5rem 0 0;
        position: relative;
    }

    .cookies-disclaimer-body {
        padding: 1.5rem 2rem 2rem 2rem;
    }

    button {
        border-radius: 0.5rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        background-color: $button-color-tertiary;
        color: white;
        border: none;
        font-weight: 600;

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    button.gray{
        background-color: #CECECE;
    }
}

@include media-breakpoint-down(md) {
    .cookies-disclaimer {
        width: 100%;
        bottom: 0;
        left: 0;
    }

    button {
        padding: 0.5rem !important;
    }
}