@import '../../index.scss';

.footer {
    background-color: $footer-color;
}

.footer-logo {
    color: black;
    font-size: 1.8rem;
    font-weight: 500;
}

.footer-titles-row {
    p {
        color: black;
        font-size: 1.3rem;
        font-weight: 500;
    }
}

.footer-links-row {
    p {
        font-size: 1rem;
        cursor: pointer;
    }
}
