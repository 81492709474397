$primary-color: #FFFF;
$secondary-color: #D4E8DD;

$button-color-primary: #FF6000;
$button-color-primary-hover: #FF7265;

$button-color-secondary: #F67474;
$button-color-secondary-hover: #FF9B9B;

$button-color-success: #38E54D;
$button-color-success-hover: #77ff87;

$button-color-danger: #FF0000;
$button-color-danger-hover: #FF4D4D;

$button-color-tertiary: #FFB64A;
$button-color-tertiary-hover: #FFCB7E;

$button-color-disabled: #EBEBE4;

$navbar-color: #F6B883;
$navbar-color-transparent: #F6AA74F5;
$footer-color: #C7003908;

$default-padding: 3%;
$default-border-radius: 1rem;
$default-box-shadow: rgba(0, 0, 0, 0.3) 2px 1px 8px;

:export {
    button-color-primary: $button-color-primary;
}