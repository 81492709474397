@import '../../index.scss';


.pricing-card {
    background-color: #FFF6BD;
    padding: 10%;
    border-radius: $default-border-radius;
    width: fit-content;
    box-shadow: $default-box-shadow;

    .card-title {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .card-price {
        font-weight: 700;
        font-size: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
    }

    .card-detail {
        font-size: 0.9rem;
        font-weight: 500;
        margin-bottom: 2rem;
    }
}