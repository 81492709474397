@import '../../index.scss';


.prices-section {
    //background-color: $primary-color;
    //background-image: linear-gradient(white, $primary-color);
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
}

.price-card-group-container {
    margin-top: 3rem;
    position: relative;
}

#conversation-classes {
    margin-top: 7rem;
}

@include media-breakpoint-down(md) {
    .prices-section {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 7%;
    }   
}
