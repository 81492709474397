@import '../../index.scss';


.dashboard-navbar {
    background-color: $navbar-color;
    padding: 1% 3% 1% 3%;
    position: relative;
}

.dashboard-navbar-brand {
    color: white;
    font-size: 1.8rem;
    font-weight: 400;
}

.dashboard-navbar-brand:hover {
    color: white;
}

.dashboard-navbar-avatar {
    cursor: pointer;
}