@import '../../index.scss';


.about-us-section {
    padding: $default-padding*1.5;
    //background-color: $primary-color;
    //background-image: linear-gradient(white, $primary-color);

    p {
        line-height: 2rem;
    }

    .highlighted-paragraph {
        background-color: $secondary-color;
    }
}